.changes-history-panel {
  margin-top: 1.5rem;
  
  .mb-1 {
    margin-bottom: 0.5rem;
  }

  .mb-2 {
    margin-bottom: 1rem;
  }

  .mb-4 {
    margin-bottom: 2rem;
  }

  .mr-2 {
    margin-right: 0.5rem;
  }

  .py-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .text-center {
    text-align: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .w-100 {
    width: 100%;
  }

  .filter-controls {
    margin-top: 12px;
  }

  .truncated-cell {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Стили для поля поиска
  .search-input {
    .ant-input-wrapper {
      .ant-input {
        height: 32px;
        min-height: 32px;
      }
    }
  }

  .ant-input-search {
    .ant-input-affix-wrapper {
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .ant-input-search-button {
      height: 36px;
    }
  }
  
  // Стили для селекторов
  .ant-select {
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 36px;
        min-height: 36px;
        padding: 0 11px;
        border-radius: 6px;
        border: 1px solid #eaedf2;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.03);
        transition: all 0.3s;
        
        &:hover {
          border-color: #40a9ff;
          box-shadow: 0 2px 8px rgba(64, 169, 255, 0.1);
        }
        
        .ant-select-selection-search {
          line-height: 36px;
        }
        
        .ant-select-selection-item {
          line-height: 34px;
        }
      }
    }
  }

  .changes-history-panel-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 20px;
    
    .status-header {
      background-color: #f5f7fa;
      padding: 10px 16px;
      margin-bottom: 0;
      border-radius: 6px 6px 0 0;
      font-weight: 500;
    }

    .changes-history-panel-collapse {
      .ant-collapse {
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #eaedf2;
        margin-bottom: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
        
        .ant-collapse-item {
          border-bottom: 1px solid #eaedf2;
          
          &:last-child {
            border-bottom: none;
          }
          
          .ant-collapse-header {
            padding: 12px 16px;
            background-color: #f8fafc;
            font-weight: 500;
            transition: all 0.3s;
            
            &:hover {
              background-color: #f0f5ff;
            }
          }
          
          .ant-collapse-content {
            .ant-collapse-content-box {
              padding: 12px;
              background-color: #fff;
            }
          }
        }
      }
    }

    table {
      margin-bottom: 0;
    }

    .ant-table-thead > tr > th {
      font-weight: 600;
      background-color: #f5f7fa;
      padding: 10px 8px;
    }

    .ant-table {
      font-size: 13px;
      border-radius: 6px;
      overflow: hidden;
      
      .ant-table-tbody > tr {
        transition: all 0.3s;
        
        &:hover {
          background-color: #f0f5ff;
        }
        
        > td {
          padding: 10px 8px;
          border-bottom: 1px solid #f0f2f7;
        }
        
        &:last-child > td {
          border-bottom: none;
        }
      }
      
      .ant-table-tbody > tr:nth-child(even) {
        background-color: #fafbfd;
      }
    }
  }
  
  // Стили для статусных индикаторов
  .ant-badge {
    &.ant-badge-status {
      .ant-badge-status-dot {
        width: 8px;
        height: 8px;
      }
      
      .ant-badge-status-text {
        margin-left: 8px;
        font-size: 13px;
      }
    }
  }
  
  // Стили для аватаров
  .ant-avatar {
    background-color: #1890ff;
    
    .anticon {
      color: #fff;
    }
  }

  .changes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .changes-info {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .changes-date {
      margin-left: auto;
    }
  }

  .changes-badge {
    
    .ant-badge-count {
      background-color: #1890ff;
      box-shadow: 0 0 0 1px #1890ff;
    }
  }
} 
