.history {
  .mb-1 {
    margin-bottom: 0.5rem;
  }

  .toggle-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    
    .toggle-label {
      margin-left: 8px;
      user-select: none;
    }

    // Стили для переключателя
    .ant-switch {
      min-width: 44px;
    }
  }
} 
